<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data: () => ({
    minutes: 5,
    seconds: '00',
    timerId: null,
    content: null,
    tipsPopup: false,

    tips1: false,
    tips2: false,
    tips1Popup: false,
    tips2Popup: false,

    tips1Select: false,
    tips2Select: false,

    timeout: false,

    mediaQuery: window.matchMedia('screen and (max-height: 920px)'),
    select: false,
    finishResult: null,
    finish: false,
    mobile: false,

    answerStage: 1,
  }),
  components: {},
  computed: {
    ...mapGetters(['getCurrentQuizData', "getSound", 'getBgMusicTime'])
  },
  beforeMount() {
    this.content = this.getCurrentQuizData.find(item => item.id === this.$route.params.id)

    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);


    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      this.mobile = true
    }
    if (navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)) {
      this.mobile = true
    }

    if (isAndroid || iOS === true) {
      this.mobile = true
    }
  },
  mounted() {
    document.title = "Нестандартна медицина в масовій культурі";
    document.body.style.overflow = "hidden"


    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh2', `${vh}px`);

    window.addEventListener("resize", () => {
      this.handleDeviceChange(this.mediaQuery)
      let vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh2', `${vh}px`);
    });

    this.$nextTick(() => {
      this.handleDeviceChange(this.mediaQuery)
      // document.getElementById('bg-audio').currentTime = this.getBgMusicTime
      if (!this.getSound) {
        document.getElementById('bg-audio').pause()
      }
    })

    this.startTimer();
  },
  beforeDestroy() {
    this.content.quiz.question.forEach((el) => {
      el.select = false
      el.disable = false
    })

    window.removeEventListener("resize", {})
    document.body.style.overflow = "visible"
  },
  methods: {

    ...mapActions(['doQuizHubStage', 'doSound', 'doBgMusicTime']),

    handleDeviceChange(e) {
      if (e.matches) {
        if (!this.mobile) {

          let hostElement = document.querySelector('#element');
          hostElement.style.height = 'auto !important'
          hostElement.style.width = '100% !important'

          let style = document.createElement('style')
          style.innerHTML = '#animation svg { width: 100% !important; height: auto!important; }' +
            '#animation {height: auto!important;justify-content: center;align-items: flex-end;}'
          hostElement.shadowRoot.appendChild(style)
        }
      } else {
        if (!this.mobile) {
          let hostElement = document.querySelector('#element');

          hostElement.style.height = '100% !important'
          hostElement.style.width = 'auto !important'

          let style = document.createElement('style')
          style.innerHTML = '#animation svg { width: auto !important; height: 100%!important;}' +
            '#animation {height: 100% !important;justify-content: center;align-items: flex-end;}'
          hostElement.shadowRoot.appendChild(style)
        }
      }
    },

    clickMusic() {
      if (this.getSound) {
        document.getElementById('click-audio').play()
      }
    },

    startTimer() {
      this.timerId = setInterval(() => {
        this.seconds--;
        if (this.seconds < 0) {
          this.seconds = 59;
          this.minutes--;
        }

        if (this.minutes < 0) {
          clearInterval(this.timerId);
          this.seconds = '00';
          this.minutes = '0';
          this.timeout = true
          return;
        }
        this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;

      }, 1000);
    },

    stopTimer() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
    },

    addTime(addMin) {
      this.minutes += addMin;
    },

    selectTips1() {
      this.tips1Select = true

      let nonConsidered = this.content.quiz.question.find(item => item.correct === true);

      let candidates = this.content.quiz.question.filter(e => e !== nonConsidered);

      let randomElements = this.getRandomElements(candidates, 2);

      randomElements.forEach((el) => {
        el.disable = true
      })

      this.tipsPopup = false

      this.tips1Popup = false
      this.tips2Popup = false
    },

    getRandomElements(arr, count) {
      let shuffled = [...arr];
      for (let i = arr.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled.slice(0, count);
    },

    selectTips2() {

      this.addTime(2)
      this.tips2Select = true
      this.tipsPopup = false


      this.tips1Popup = false
      this.tips2Popup = false
    },

    checkAnswer(index, isDisable) {
      if (!isDisable && !this.select) {
        this.select = true

        this.content.quiz.question[index].select = true
        this.stopTimer()
        if (this.getSound) {
          document.getElementById('click-audio').play()

        }

        setTimeout(() => {
          this.finishResult = this.content.quiz.question[index].correct
          this.answerStage = 2

          if (this.getSound) {
            if (this.finishResult) {
              document.getElementById('win-audio').play()
            } else {
              document.getElementById('lose-audio').play()
            }
          }
        }, 1500)

      }
    },

    returnToAll() {
      this.content.quiz.question.forEach((el) => {
        el.select = false
        el.disable = false
      })

      this.doQuizHubStage(3)
      setTimeout(() => {
        // this.doBgMusicTime(document.getElementById('bg-audio').currentTime)
        this.$router.push({name: 'quizHub'})
      }, 150)
    },

    mute() {
      let audio = document.getElementById('bg-audio');
      if (!this.getSound) {
        audio.play()
        this.doSound(true)
      } else {
        audio.pause()
        this.doSound(false)
      }
    },
  }
}
</script>

<template>
  <div class="quiz-game">
    <audio id="click-audio" src="@/assets/audio/btn-click.mp3"></audio>
    <audio id="bg-audio" src="@/assets/audio/bg-music2.mp3" loop autoplay></audio>
    <audio id="win-audio" src="@/assets/audio/positive.mp3"></audio>
    <audio id="lose-audio" src="@/assets/audio/negative.mp3"></audio>

    <div class="sound-btn" @click="mute()">
      <p class="sound-btn__text">звук</p>
      <div class="sound-btn__icon">
        <svg v-if="getSound" width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.4091 1.38477H10.2771L7.11082 4.14247C6.7467 4.4596 6.28014 4.6343 5.79727 4.6343H4.01563C2.91106 4.6343 2.01562 5.52973 2.01562 6.6343V9.36547C2.01562 10.47 2.91106 11.3655 4.01563 11.3655H5.79727C6.28014 11.3655 6.7467 11.5402 7.11082 11.8573L10.2771 14.615H12.4091V1.38477Z"
            fill="black"/>
        </svg>

        <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9832 3.625L2.99316 14.615" stroke="black" stroke-width="1.4" stroke-linecap="round"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.4091 3.078V1.38477H10.2771L7.11082 4.14247C6.7467 4.4596 6.28014 4.6343 5.79727 4.6343H4.01563C2.91106 4.6343 2.01562 5.52973 2.01562 6.6343V9.36547C2.01562 10.47 2.91106 11.3655 4.01563 11.3655H4.12165L12.4091 3.078ZM12.4091 7.32064L7.51791 12.2118L10.2771 14.615H12.4091V7.32064Z"
                fill="black"/>
        </svg>

      </div>
    </div>

    <div class="img">
      <div class="animation1">
        <lottie-player v-if="!mobile" id="element" src="/animation/bg-animation-full.json" background="transparent"
                       speed="1"
                       style=";width: auto; height: 100%; display: flex;justify-content: center"
                       autoplay loop>
        </lottie-player>
        <img v-else class="bg-mobile" src="@/assets/img/quiz-game/bg-img-tm.jpg" alt="">
        <div class="gradient"></div>
      </div>
    </div>

    <div class="game" v-show="!finish"
         :class="[{inCorrect: finishResult === false && answerStage === 2}, {correct: finishResult === true && answerStage === 2}]">
      <div class="game__content">

        <div class="corner corner1">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="corner corner2">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.55184e-08 1H14C15.1046 1 16 1.89543 16 3V17" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="corner corner3">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 16H3C1.89543 16 1 15.1046 1 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="corner corner4">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.55184e-08 16H14C15.1046 16 16 15.1046 16 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div :class="{inCorrect: finishResult === false && answerStage === 2}" class="game__title">

          <div class="num" v-html="content.id"></div>

          <div class="name">
            <p v-html="content.name"></p>
          </div>

          <div class="timer">
            <p>{{ minutes }}:{{ seconds }} хв</p>
          </div>

        </div>

        <div v-show="answerStage === 1" class="game__border">

          <div class="game__info">
            <div class="scroll">
              <div class="text">
                <p v-html="content.quiz.text1"></p>
              </div>

              <!--              <picture>-->
              <!--                <source media="(max-width: 1023px)"-->
              <!--                        :srcset="require(`@/assets/img/quiz-game/${content.id}/m-${content.quiz.img1}`)">-->
              <img class="img-right" :src="require(`@/assets/img/quiz-game/${content.id}/${content.quiz.img1}`)"
                   alt="">
              <!--              </picture>-->
            </div>
          </div>

          <div class="game__answers">

            <div class="tips" @click="tipsPopup = true; clickMusic()">
              <div class="icon">
                <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.5 1.12354C8.5 1.38161 8.29079 1.59082 8.03271 1.59082C7.77464 1.59082 7.56543 1.38161 7.56543 1.12354V0.510254C7.56543 0.252179 7.77464 0.0429688 8.03271 0.0429688C8.29079 0.0429688 8.5 0.252179 8.5 0.510254V1.12354Z"
                    fill="#06243E"/>
                  <path
                    d="M1.49121 6.58447C1.49121 6.84255 1.70042 7.05176 1.9585 7.05176H2.57178C2.82985 7.05176 3.03906 6.84255 3.03906 6.58447C3.03906 6.3264 2.82985 6.11719 2.57178 6.11719H1.9585C1.70042 6.11719 1.49121 6.3264 1.49121 6.58447Z"
                    fill="#06243E"/>
                  <path
                    d="M13.0264 6.58447C13.0264 6.84255 13.2356 7.05176 13.4937 7.05176H14.1069C14.365 7.05176 14.5742 6.84255 14.5742 6.58447C14.5742 6.3264 14.365 6.11719 14.1069 6.11719H13.4937C13.2356 6.11719 13.0264 6.3264 13.0264 6.58447Z"
                    fill="#06243E"/>
                  <path
                    d="M4.50196 3.0534C4.68444 2.87091 4.68444 2.57504 4.50196 2.39256L4.0683 1.9589C3.88582 1.77642 3.58995 1.77642 3.40746 1.9589C3.22498 2.14139 3.22498 2.43726 3.40746 2.61974L3.84112 3.0534C4.0236 3.23588 4.31947 3.23588 4.50196 3.0534Z"
                    fill="#06243E"/>
                  <path
                    d="M12.6585 10.5491C12.841 10.7316 12.841 11.0275 12.6585 11.21C12.4761 11.3925 12.1802 11.3925 11.9977 11.21L11.564 10.7763C11.3816 10.5938 11.3816 10.298 11.564 10.1155C11.7465 9.933 12.0424 9.933 12.2249 10.1155L12.6585 10.5491Z"
                    fill="#06243E"/>
                  <path
                    d="M4.50225 10.1155C4.31976 9.93299 4.0239 9.93299 3.84141 10.1155L3.40775 10.5491C3.22527 10.7316 3.22527 11.0275 3.40775 11.21C3.59024 11.3925 3.88611 11.3925 4.06859 11.21L4.50225 10.7763C4.68474 10.5938 4.68474 10.298 4.50225 10.1155Z"
                    fill="#06243E"/>
                  <path
                    d="M11.998 1.95889C12.1805 1.7764 12.4764 1.7764 12.6588 1.95889C12.8413 2.14137 12.8413 2.43724 12.6588 2.61973L12.2252 3.05338C12.0427 3.23587 11.7468 3.23587 11.5643 3.05338C11.3819 2.8709 11.3819 2.57503 11.5643 2.39254L11.998 1.95889Z"
                    fill="#06243E"/>
                  <path
                    d="M10.8066 9.97769C10.6135 10.1389 10.4709 10.3565 10.4313 10.6049L10.1972 12.0734C10.1259 12.5207 9.74015 12.8498 9.28723 12.8498H6.7782C6.32528 12.8498 5.93952 12.5207 5.86822 12.0734L5.63411 10.6049C5.59452 10.3565 5.45188 10.1389 5.25878 9.97769C4.30685 9.18313 3.70117 7.98768 3.70117 6.65076C3.70117 4.25851 5.64047 2.31921 8.03271 2.31921C10.425 2.31921 12.3643 4.25851 12.3643 6.65076C12.3643 7.98768 11.7586 9.18313 10.8066 9.97769Z"
                    fill="#06243E"/>
                  <path
                    d="M6.72803 14.4806C6.46995 14.4806 6.26074 14.2714 6.26074 14.0133C6.26074 13.7552 6.46995 13.546 6.72803 13.546H9.33643C9.5945 13.546 9.80371 13.7552 9.80371 14.0133C9.80371 14.2714 9.5945 14.4806 9.33643 14.4806H6.72803Z"
                    fill="#06243E"/>
                  <path
                    d="M6.79199 15.5327C6.79199 15.7908 7.0012 16 7.25928 16H8.80615C9.06423 16 9.27344 15.7908 9.27344 15.5327C9.27344 15.2746 9.06423 15.0654 8.80615 15.0654H7.25928C7.0012 15.0654 6.79199 15.2746 6.79199 15.5327Z"
                    fill="#06243E"/>
                </svg>
              </div>
              <p>підказки</p>
            </div>

            <p class="title-text">Ваша відповідь</p>

            <div class="btn-wrapper">
              <div
                :class="[{disable: btn.disable},{selected: select}, {incorrect: !btn.correct && btn.select}, {correct: btn.correct && btn.select}]"
                class="btn"
                v-for="(btn, index) in content.quiz.question"
                @click="checkAnswer(index, btn.disable)">
                <p v-html="btn.name"></p>
              </div>
            </div>

            <div v-show="tipsPopup" class="select-tips-popup">
              <p class="popup-title">Підказки</p>

              <div class="close-btn" @click="tipsPopup = false;clickMusic()">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M17.6569 6.54415C17.2664 6.15362 16.6332 6.15362 16.2427 6.54415L12.0001 10.7867L7.75742 6.54406C7.3669 6.15354 6.73373 6.15354 6.34321 6.54406C5.95268 6.93458 5.95268 7.56775 6.34321 7.95827L10.5859 12.2009L6.54424 16.2426C6.15371 16.6331 6.15371 17.2663 6.54424 17.6568C6.93476 18.0473 7.56793 18.0473 7.95845 17.6568L12.0001 13.6152L16.0416 17.6567C16.4322 18.0472 17.0653 18.0472 17.4558 17.6567C17.8464 17.2662 17.8464 16.633 17.4558 16.2425L13.4143 12.2009L17.6569 7.95836C18.0474 7.56784 18.0474 6.93467 17.6569 6.54415Z"
                        fill="#06243E"/>
                </svg>
              </div>

              <div :class="{disable: tips1Select}" class="wrapper">
                <div @click="!tips1Select ? tips1Popup = !tips1Popup : null;clickMusic()"
                     :class="{disable: tips1Select}"
                     class="m-b popup-btn">
                  <p>50/50</p>
                </div>
                <svg v-show="!tips1" @click="!tips1Select ? tips1 = !tips1 : null;clickMusic()" viewBox="0 0 32 32"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="16" cy="16" r="13" stroke="#06243E" stroke-width="2"/>
                  <path
                    d="M16.9103 18.528C16.75 19.0565 16.3172 19.5138 15.7649 19.5138H15.5328C15.0613 19.5138 14.6403 19.184 14.6235 18.7128C14.6018 18.1004 14.7121 17.4845 15.0102 16.9442C15.2822 16.461 15.6087 16.0657 15.9895 15.7582C16.3704 15.4507 16.7513 15.1506 17.1322 14.8577C17.513 14.5502 17.8327 14.1988 18.0911 13.8035C18.3632 13.4082 18.4992 12.969 18.4992 12.4858C18.4992 11.8562 18.2748 11.3364 17.8259 10.9264C17.3906 10.5018 16.7989 10.2895 16.0508 10.2895C15.0986 10.2895 14.3096 10.6556 13.6839 11.3877C13.6504 11.4298 13.6178 11.473 13.586 11.5173C13.2412 11.9985 12.6321 12.2932 12.086 12.0646C11.6106 11.8656 11.3448 11.3392 11.5644 10.8729C11.723 10.5359 11.9194 10.217 12.1536 9.91617C13.1194 8.70092 14.4184 8.09329 16.0508 8.09329C17.4246 8.09329 18.5468 8.50326 19.4174 9.32319C20.3015 10.1431 20.7436 11.1241 20.7436 12.2662C20.7436 13.0275 20.6076 13.701 20.3356 14.2867C20.0635 14.8577 19.7302 15.3263 19.3358 15.6923C18.9549 16.0437 18.5672 16.3804 18.1727 16.7026C17.5721 17.193 17.1264 17.8158 16.9103 18.528ZM14.8061 23.489C14.5477 23.1961 14.4184 22.8594 14.4184 22.4787C14.4184 22.098 14.5477 21.7686 14.8061 21.4904C15.0782 21.1975 15.391 21.0511 15.7447 21.0511C16.0984 21.0511 16.4044 21.1975 16.6629 21.4904C16.9349 21.7686 17.0709 22.098 17.0709 22.4787C17.0709 22.8594 16.9349 23.1961 16.6629 23.489C16.4044 23.7671 16.0984 23.9062 15.7447 23.9062C15.391 23.9062 15.0782 23.7671 14.8061 23.489Z"
                    fill="#06243E"/>
                </svg>
              </div>

              <div v-show="tips1" class="m-b popup-info">
                <p>Підказка "50/50" прибирає дві не вірних відповіді та залишає всього два варіанти відповіді, де один
                  вірний, інший ні.
                </p>
                <div @click="tips1 = !tips1;clickMusic()">
                  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="30" height="30" rx="15" stroke="#32AC82" stroke-width="2"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M21.6569 10.5442C21.2664 10.1537 20.6332 10.1537 20.2427 10.5442L16.0001 14.7868L11.7574 10.5441C11.3669 10.1536 10.7337 10.1536 10.3432 10.5441C9.95268 10.9346 9.95268 11.5678 10.3432 11.9583L14.5859 16.201L10.5442 20.2426C10.1537 20.6332 10.1537 21.2663 10.5442 21.6568C10.9348 22.0474 11.5679 22.0474 11.9585 21.6568L16.0001 17.6152L20.0416 21.6568C20.4322 22.0473 21.0653 22.0473 21.4558 21.6568C21.8464 21.2662 21.8464 20.6331 21.4558 20.2425L17.4143 16.201L21.6569 11.9584C22.0474 11.5679 22.0474 10.9347 21.6569 10.5442Z"
                          fill="#3CEAAE"/>
                  </svg>
                </div>
              </div>

              <div :class="{disable: tips2Select}" class="wrapper">
                <div @click="!tips2Select ? tips2Popup = !tips2Popup : null;clickMusic()" class="m-b popup-btn"
                     :class="{disable: tips2Select}">
                  <p>
                    2 хв
                  </p>
                </div>
                <svg v-show="!tips2" @click="!tips2Select ? tips2 = !tips2 : null;clickMusic()" viewBox="0 0 32 32"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="16" cy="16" r="13" stroke="#06243E" stroke-width="2"/>
                  <path
                    d="M16.9103 18.528C16.75 19.0565 16.3172 19.5138 15.7649 19.5138H15.5328C15.0613 19.5138 14.6403 19.184 14.6235 18.7128C14.6018 18.1004 14.7121 17.4845 15.0102 16.9442C15.2822 16.461 15.6087 16.0657 15.9895 15.7582C16.3704 15.4507 16.7513 15.1506 17.1322 14.8577C17.513 14.5502 17.8327 14.1988 18.0911 13.8035C18.3632 13.4082 18.4992 12.969 18.4992 12.4858C18.4992 11.8562 18.2748 11.3364 17.8259 10.9264C17.3906 10.5018 16.7989 10.2895 16.0508 10.2895C15.0986 10.2895 14.3096 10.6556 13.6839 11.3877C13.6504 11.4298 13.6178 11.473 13.586 11.5173C13.2412 11.9985 12.6321 12.2932 12.086 12.0646C11.6106 11.8656 11.3448 11.3392 11.5644 10.8729C11.723 10.5359 11.9194 10.217 12.1536 9.91617C13.1194 8.70092 14.4184 8.09329 16.0508 8.09329C17.4246 8.09329 18.5468 8.50326 19.4174 9.32319C20.3015 10.1431 20.7436 11.1241 20.7436 12.2662C20.7436 13.0275 20.6076 13.701 20.3356 14.2867C20.0635 14.8577 19.7302 15.3263 19.3358 15.6923C18.9549 16.0437 18.5672 16.3804 18.1727 16.7026C17.5721 17.193 17.1264 17.8158 16.9103 18.528ZM14.8061 23.489C14.5477 23.1961 14.4184 22.8594 14.4184 22.4787C14.4184 22.098 14.5477 21.7686 14.8061 21.4904C15.0782 21.1975 15.391 21.0511 15.7447 21.0511C16.0984 21.0511 16.4044 21.1975 16.6629 21.4904C16.9349 21.7686 17.0709 22.098 17.0709 22.4787C17.0709 22.8594 16.9349 23.1961 16.6629 23.489C16.4044 23.7671 16.0984 23.9062 15.7447 23.9062C15.391 23.9062 15.0782 23.7671 14.8061 23.489Z"
                    fill="#06243E"/>
                </svg>
              </div>

              <div v-show="tips2" class="m-b popup-info">
                <p>Підказка “2 хв” дає більше часу для того, щоб ви подумали над вашим запитанням.</p>
                <div @click="tips2 = !tips2; clickMusic()">
                  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="30" height="30" rx="15" stroke="#32AC82" stroke-width="2"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M21.6569 10.5442C21.2664 10.1537 20.6332 10.1537 20.2427 10.5442L16.0001 14.7868L11.7574 10.5441C11.3669 10.1536 10.7337 10.1536 10.3432 10.5441C9.95268 10.9346 9.95268 11.5678 10.3432 11.9583L14.5859 16.201L10.5442 20.2426C10.1537 20.6332 10.1537 21.2663 10.5442 21.6568C10.9348 22.0474 11.5679 22.0474 11.9585 21.6568L16.0001 17.6152L20.0416 21.6568C20.4322 22.0473 21.0653 22.0473 21.4558 21.6568C21.8464 21.2662 21.8464 20.6331 21.4558 20.2425L17.4143 16.201L21.6569 11.9584C22.0474 11.5679 22.0474 10.9347 21.6569 10.5442Z"
                          fill="#3CEAAE"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="answerStage === 2" class="game__result"
             :class="[{inCorrect: finishResult === false}, {correct: finishResult === true}]">
          <div class="scroll ">
            <div class="grid-item-first-column ">
              <div class="game__result--wrapper">
                <p class="game__result--title" v-show="!finishResult">А от і ні!</p>
                <p class="game__result--title" v-show="finishResult">Саме так!</p>

                <p class="game__result--text" v-html="content.quiz.text2"></p>
              </div>
              <div class="game__result--wrapper">
                <!--                <picture>-->
                <!--                  <source media="(max-width: 1023px)"-->
                <!--                          :srcset="require(`@/assets/img/quiz-game/${content.id}/m-${content.quiz.img2}`)">-->
                <img class="img-right" :src="require(`@/assets/img/quiz-game/${content.id}/${content.quiz.img2}`)"
                     alt="">
                <!--                </picture>-->
              </div>
            </div>
          </div>

          <div class="game__result--btn" @click="answerStage = 3 ;clickMusic()">
            <p>далі</p>
          </div>
        </div>

        <div v-show="answerStage === 3" class="game__pack-info">
          <div class="scroll">
            <p v-show="content.quiz.text3.text1" class="game__pack-info--text" v-html="content.quiz.text3.text1"></p>

            <div class="game__pack-info--wrapper" v-for="item in content.quiz.text3.items">
              <div class="game__pack-info--item">
                <div class="game__pack-info--item-title"
                     v-show="item.title1"
                     v-html="item.title1"></div>
                <div class="game__pack-info--item-big-text"
                     v-show="item.text2"
                     v-html="item.text2"></div>
                <div class="game__pack-info--item-text"
                     v-show="item.text3"
                     v-html="item.text3"></div>

                <div class="game__pack-info--item-title second"
                     v-show="item.text4"
                     v-html="item.text4"></div>
                <div class="game__pack-info--item-text "
                     v-show="item.text5"
                     v-html="item.text5"></div>
              </div>

              <!--              <picture>-->
              <!--                <source media="(max-width: 1023px)"-->
              <!--                        :srcset="require(`@/assets/img/quiz-game/${content.id}/m-${item.img3}`)">-->
              <!--              </picture>-->
              <img class="img-right" :src="require(`@/assets/img/quiz-game/${content.id}/${item.img3}`)" alt="">
            </div>

            <ul class="game__pack-info--links">
              <li v-for="link in content.quiz.text3.links" v-html="link"></li>
            </ul>
          </div>

          <div class="game__pack-info--btn" @click="finish = true; clickMusic()">
            <p>Наступне завдання</p>
          </div>
        </div>

        <div v-show="timeout" class="timeout">
          <div class="timeout__wrapper">

            <div class="corner corner1">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="corner corner2">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.55184e-08 1H14C15.1046 1 16 1.89543 16 3V17" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="corner corner3">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 16H3C1.89543 16 1 15.1046 1 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="corner corner4">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.55184e-08 16H14C15.1046 16 16 15.1046 16 14V4.55184e-08" stroke="#94FFDA"
                      stroke-width="2"/>
              </svg>
            </div>

            <div class="timeout__content">

              <div class="timeout__title">
                <p>Увага!</p>
              </div>

              <div class="timeout__border">

                <p class="timeout__text">
                  На жаль час на відповідь закінчився
                </p>

                <div class="timeout__btn" @click='returnToAll();clickMusic()'>
                  <p>продовжити</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tips-popup-wrapper" v-show="tips1Popup">
          <div class="tips-popup">
            <div class="corner corner1">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="corner corner2">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.55184e-08 1H14C15.1046 1 16 1.89543 16 3V17" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="corner corner3">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 16H3C1.89543 16 1 15.1046 1 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="corner corner4">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.55184e-08 16H14C15.1046 16 16 15.1046 16 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="tips-popup__content">
              <div class="tips-popup__title">
                <p>Увага!</p>
              </div>
              <div class="tips-popup__text">
                <p>Ви підтверджуєте використання
                  підказки 50/50?</p>
              </div>
              <div class="tips-popup__btn-wrapper">
                <div class="btn" @click="tips1Popup = false;clickMusic()">
                  <p>ні</p>
                </div>
                <div class="btn" @click="!tips1Select ? selectTips1() : null;clickMusic()">
                  <p class="color">так</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tips-popup-wrapper" v-show="tips2Popup">
          <div class="tips-popup">
            <div class="corner corner1">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="corner corner2">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.55184e-08 1H14C15.1046 1 16 1.89543 16 3V17" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="corner corner3">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 16H3C1.89543 16 1 15.1046 1 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="corner corner4">
              <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.55184e-08 16H14C15.1046 16 16 15.1046 16 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
              </svg>
            </div>

            <div class="tips-popup__content">
              <div class="tips-popup__title">
                <p>Увага!</p>
              </div>
              <div class="tips-popup__text">
                <p>Ви підтверджуєте використання
                  підказки 2&nbsp;хв?</p>
              </div>
              <div class="tips-popup__btn-wrapper">
                <div class="btn" @click="tips2Popup = false;clickMusic()">
                  <p>ні</p>
                </div>
                <div class="btn " @click="!tips2Select ? selectTips2() : null;clickMusic()">
                  <p class="color">так</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="finish" v-show="finish">

      <div class="corner corner1">
        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17" stroke="#94FFDA" stroke-width="2"/>
        </svg>
      </div>

      <div class="corner corner2">
        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.55184e-08 1H14C15.1046 1 16 1.89543 16 3V17" stroke="#94FFDA" stroke-width="2"/>
        </svg>
      </div>

      <div class="corner corner3">
        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 16H3C1.89543 16 1 15.1046 1 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
        </svg>
      </div>

      <div class="corner corner4">
        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.55184e-08 16H14C15.1046 16 16 15.1046 16 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
        </svg>
      </div>

      <div class="finish__content">
        <div class="finish__title">
          <p>Вітаємо!</p>
        </div>
        <div class="finish__border">
          <div class="finish__text">
            <p>Тепер ви знаєте як було не просто лікарям та паціентам в тому столітті.</p>
          </div>
          <div class="finish__btn" @click="returnToAll();clickMusic()">
            <p>до фактів</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#element {
  @supports (hanging-punctuation: first) and (-webkit-appearance: none) {
    width: 100% !important;
  }
}

#bg-audio {
  position: fixed;
  left: -100%;
  top: -100%;
}

#playButton, #click-audio {
  position: fixed;
  left: -100%;
  top: -100%;
}

::v-deep sub {
  font-size: 70%;
  line-height: 0;
}

@keyframes blinkIncorrect {
  0% {
    background-color: rgba(225, 0, 0, 0);
  }
  33.33% {
    background-color: rgba(225, 0, 0, 1);
  }

  66.66% {
    background-color: rgba(225, 0, 0, .3);
  }
  100% {
    background-color: rgba(225, 0, 0, 1);
  }
}

@keyframes blinkCorrect {
  0% {
    background-color: rgba(60, 234, 174, 0);
  }
  33.33% {
    background-color: rgba(60, 234, 174, 1);
  }

  66.66% {
    background-color: rgba(60, 234, 174, .3);
  }
  100% {
    background-color: rgba(60, 234, 174, 1);
  }
}

@keyframes blinkBorderRed {
  0% {
    border-color: rgba(225, 0, 0, 1);
    box-shadow: 0 0 120px 0 #E10000;
  }
  33.33% {
    border-color: rgba(225, 0, 0, .2);
    box-shadow: 0 0 20px 0 #E10000;
  }
  50% {
    border-color: rgba(225, 0, 0, 1);

    box-shadow: 0 0 120px 0 #E10000;
  }
  83.33% {
    border-color: rgba(225, 0, 0, .2);
    box-shadow: 0 0 20px 0 #E10000;
  }
  100% {
    border-color: rgba(225, 0, 0, 1);
    box-shadow: 0 0 120px 0 #E10000;
  }
}

@keyframes blinkBorderGreen {
  0% {
    border-color: rgba(60, 234, 174, 1);
    box-shadow: 0 0 120px 0 rgba(60, 234, 174, 1);
  }
  33.33% {
    border-color: rgba(60, 234, 174, .2);
    box-shadow: 0 0 20px 0 rgba(60, 234, 174, 1);
  }
  50% {
    border-color: rgba(60, 234, 174, 1);
    box-shadow: 0 0 120px 0 rgba(60, 234, 174, 1);
  }
  83.33% {
    border-color: rgba(60, 234, 174, .2);
    box-shadow: 0 0 20px 0 rgba(60, 234, 174, 1);
  }
  100% {
    border-color: rgba(60, 234, 174, 1);
    box-shadow: 0 0 120px 0 rgba(60, 234, 174, 1);
  }
}

.quiz-game {
  background: #06243E;
  padding: 10px;
  max-height: calc((var(--vh2, 1vh) * 100));
  min-height: calc((var(--vh2, 1vh) * 100));
  height: 100vh;
  height: calc((var(--vh2, 1vh) * 100));
  width: 100%;
  font-family: 'IBM Plex Mono', monospace;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding-top: 80px;

  @media screen and (max-width: 600px) {
    padding: 8px 16px;
    padding-top: 64px;
  }

  .sound-btn {
    position: absolute;
    z-index: 20;
    right: 32px;
    top: 18px;
    display: flex;
    align-items: center;

    @media screen and (min-width: 1023px) {

      &:hover {
        cursor: pointer;

        .sound-btn__icon {
          background: var(--green-200, #94FFDA);
        }
      }
    }

    @media screen and (max-width: 600px) {
      top: 10px;
      right: 14px;
    }

    &__text {
      color: #FFF;
      text-align: center;

      font-size: 14px;
      font-weight: 700;
      line-height: 120% !important;
      letter-spacing: -0.28px;
      text-transform: uppercase;
      margin-right: 16px;
    }

    &__icon {
      transition: all .2s ease-in;
      border: 1px solid var(--green-500, #32AC82);
      background: var(--green-300, #3CEAAE);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
    }
  }

  ::v-deep sup {
    font-size: 60%;
    line-height: 0;
    vertical-align: top;
  }

  .img {
    position: absolute;
    z-index: 1;
    left: 10px;
    right: 10px;
    bottom: 0;
    top: 10px;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    overflow: hidden;

    align-items: center;
    justify-content: center;


    .animation1 {
      position: absolute;
      overflow: hidden;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .gradient {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(6, 36, 62, 0.00) 78.46%, #06243E 97.04%);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .corner {
    position: absolute;

    svg {
      width: 17px;
      height: auto;
      display: block;
    }

    &.corner1 {
      left: -3px;
      top: -3px;
    }

    &.corner2 {
      right: -3px;
      top: -3px;
    }

    &.corner3 {
      left: -3px;
      bottom: -3px;
    }

    &.corner4 {
      right: -3px;
      bottom: -3px;
    }
  }

  .game {
    position: relative;
    z-index: 2;
    border: 2px solid rgba(38, 158, 117, 0.20);
    background: var(--grey-t-30, rgba(66, 80, 115, 0.30));

    max-width: calc(1188px + 32px);
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;

    padding: 16px;
    max-height: calc(100% - 70px);
    height: 100%;

    &.correct {
      border-radius: 3px;
      border-color: rgba(60, 234, 174, 1);
      box-shadow: 0 0 120px 0 #3CEAAE;
      animation: blinkBorderGreen 3s linear infinite;
      transition: border-color .2s ease-in;

    }

    &.inCorrect {
      border-radius: 3px;
      border-color: rgba(225, 0, 0, 1);
      box-shadow: 0 0 120px 0 #E10000;
      animation: blinkBorderRed 3s linear infinite;
      transition: border-color .2s ease-in;
    }

    @media screen and (max-width: 1023px) {
      max-height: calc(100% - 23px);
    }

    @media screen and (max-height: 838px) {
      //max-height: calc(100% - 60px);
    }

    @media screen and (max-width: 600px) {
      max-height: calc(100% - 24px);
      margin-left: 0;
      margin-right: 0;
      padding: 8px;
    }

    &__content {

      display: flex;
      height: 100%;
      flex-direction: column;
    }

    &__border {
      border: 1px solid var(--gr-otline-frame, #44A483);
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &__title {
      background: var(--green-300, #3CEAAE);
      padding: 16px;
      position: relative;

      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 65px;

      @media screen and (max-width: 1023px) {
        height: 94px;
        padding: 31px 16px;
      }

      @media screen and (max-width: 600px) {
        flex-flow: row wrap;
        height: 108px;
        padding: 16px;

      }

      &.inCorrect {
        background: #E10000;

        .num {
          color: white;
        }

        .name {
          p {
            color: white;
          }
        }

        .timer {
          border-color: white;

          p {
            color: white;
          }
        }

      }

      .num {
        color: var(--bg, #06243E);
        font-size: 12px;
        font-weight: 700;
        line-height: 10px !important;
        @media screen and (max-width: 600px) {
          order: 1;
        }
      }

      .name {
        position: absolute;
        left: 90px;
        right: 90px;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1023px) {
          left: 100px;
          right: 100px;
        }

        @media screen and (max-width: 600px) {
          position: relative;
          left: 0;
          right: 0;
          order: 2;
          display: flex;
          margin-top: 8px;
          width: 100%;
        }

        p {
          color: var(--bg, #06243E);
          text-align: center;

          font-size: 24px;
          font-weight: 700;
          line-height: normal !important;
          text-transform: uppercase;

          @media screen and (max-width: 600px) {
            font-size: 16px;
            letter-spacing: -0.16px;
            text-transform: uppercase;
            max-width: 280px;
          }
        }
      }

      .timer {
        padding: 8px;
        border: 1px solid var(--grey-t-80, rgba(18, 23, 34, 0.80));

        @media screen and (max-width: 600px) {
          order: 1;
          padding: 6px;
        }

        p {
          color: var(--bg, #06243E);
          text-align: center;

          font-size: 14px;
          font-weight: 700;
          line-height: 120% !important;
          letter-spacing: -0.28px;
          text-transform: uppercase;
          @media screen and (max-width: 600px) {
            font-size: 12px;
          }
        }
      }
    }

    &__info {
      padding: 40px 16px;
      border-bottom: 1px solid var(--gr-otline-frame, #44A483);
      background: var(--grey-t-80, rgba(18, 23, 34, 0.80));

      height: 100%;

      @media screen and (max-width: 600px) {
        padding: 16px 8px;
      }

      .scroll {
        padding: 0 24px;
        display: grid;
        grid-template-columns: 1fr 1fr; /* specifies 2 columns with equal width */
        grid-gap: 24px;

        max-height: calc((var(--vh2, 1vh) * 100) - 160px - 314px - 65px - 118px + 14px);
        overflow-y: auto;
        overflow-x: hidden;

        @media screen and (max-height: 830px) and (min-width: 601px) {
          max-height: calc((var(--vh2, 1vh) * 100) - 8px - 8px - 279px - 108px - 16px - 59px - 43px - 4px - 24px);

        }

        @media screen and (max-width: 1023px) {
          max-height: calc((var(--vh2, 1vh) * 100) - 160px - 314px - 94px - 38px - 10px - 23px + 14px);
          grid-template-columns: 1fr;
        }
        //@media screen and (max-height: 838px) {
        //  max-height: calc((var(--vh2, 1vh) * 100) - 160px - 314px - 94px - 90px);
        //}
        //@media screen and (max-height: 838px) and (max-width: 1023px) {
        //  max-height: calc((var(--vh2, 1vh) * 100) - 160px - 314px - 94px - 38px - 90px);
        //}
        @media screen and (max-width: 600px) {
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          max-height: calc((var(--vh2, 1vh) * 100) - 8px - 8px - 279px - 108px - 16px - 59px - 43px - 4px + 30px - 24px);
          height: 100%;

          padding: 0 8px;

        }

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background-color: rgba(241, 241, 241, 0.25) !important;
          border-radius: 3px;

        }

        &::-webkit-scrollbar-thumb {
          background-color: #32AC82 !important;
          border-radius: 3px;
        }

      }

      .text {
        color: var(--White, #FFF);

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140% !important;

        ::v-deep i {
          font-style: italic;
        }

        @media screen and (max-width: 600px) {
          font-size: 13px;
          line-height: 131% !important;
        }
      }

      .img-right {

        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__answers {
      margin-top: auto;

      padding: 16px;

      position: relative;

      @media screen and (max-width: 600px) {
        padding: 8px;

      }

      .tips {
        display: flex;
        align-items: center;

        @media screen and (min-width: 1023px) {
          &:hover {
            cursor: pointer;

            .icon {
              background: var(--green-200, #94FFDA);
            }
          }
        }

        &:active {
          .icon {
            background: var(--green-200, #94FFDA);
          }
        }

        .icon {
          width: 40px;
          height: 40px;

          border: 1px solid var(--green-500, #32AC82);
          background: var(--green-300, #3CEAAE);

          transition: background-color .2s ease-in;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 11px;

          svg {
            width: 16px;
            height: auto;
            display: block;
          }
        }

        p {
          color: #FFF;
          text-align: center;

          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120% !important;
          letter-spacing: -0.28px;
          text-transform: uppercase;


          @media screen and (max-width: 600px) {
            font-size: 11px;
          }
        }
      }

      .select-tips-popup {
        position: absolute;
        left: -1px;
        bottom: -2px;
        right: -1px;
        width: calc(100% + 2px);

        border: 1px solid var(--White, #FFF);
        background: var(--grey-t-80, rgba(18, 23, 34, 0.80));
        padding: 16px;

        .popup-title {
          color: var(--White, #FFF);

          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal !important;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 32px;

          @media screen and (max-width: 600px) {

            font-size: 14px;
            line-height: 120% !important;
            letter-spacing: -0.28px;
            padding: 8px 0;
          }
        }

        .wrapper {
          position: relative;

          &.disable {

            .popup-btn {

              border: 1px solid #36C695;
              opacity: 0.5;
              background: var(--grey-t-80, rgba(18, 23, 34, 0.80));

              p {
                color: white;
              }
            }

            svg {
              opacity: 0.5;

              circle {
                stroke: white;
              }

              path {
                fill: white;
              }

            }
          }

          &:not(.disable) {
            svg {
              @media screen and (min-width: 1023px) {

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }

          svg {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 32px;
            height: auto;
            margin: auto 24px;
            display: block;


            @media screen and (max-width: 600px) {
              margin: auto 16px;
            }
          }
        }

        .popup-btn {
          display: flex;
          justify-content: center;
          align-items: center;

          padding: 16px 4px;
          border: 2px solid rgba(255, 255, 255, 0.50);
          background: var(--green-300, #3CEAAE);
          position: relative;
          transition: background .2s ease-in;

          @media screen and (min-width: 1023px) {
            &:not(.disable) {

              &:hover {
                cursor: pointer;
                background: var(--green-200, #94FFDA);
              }
            }
          }


          &.m-b {
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          svg {
            position: absolute;
            right: 24px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 32px;
            height: auto;
            display: block;
            @media screen and (max-width: 600px) {
              right: 16px;
            }
          }

          p {
            color: var(--bg, #06243E);
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 160% !important;
            text-transform: uppercase;

            @media screen and (max-width: 600px) {
              font-size: 24px;
              line-height: 160% !important;
              letter-spacing: -0.48px;
            }
          }
        }

        .popup-info {
          padding: 16px 24px;
          background: var(--bg, #06243E);
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: 600px) {
            align-items: flex-start;
          }

          p {
            color: var(--White, #FFF);

            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140% !important;

            @media screen and (max-width: 600px) {
              font-size: 15px;
            }
          }

          &.m-b {
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          svg {
            width: 32px;
            height: auto;
            margin-left: 16px;

            path, rect {
              transition: all .2s ease-in;
            }

            @media screen and (min-width: 1023px) {
              &:hover {
                rect {
                  stroke: #94FFDA;
                }

                path {
                  fill: #94FFDA;
                }
              }
            }

            &:active {
              rect {
                stroke: #94FFDA;
              }

              path {
                fill: #94FFDA;
              }
            }

            @media screen and (min-width: 1023px) {
              &:hover {
                cursor: pointer;
              }
            }
          }

        }

        .close-btn {
          position: absolute;
          right: 16px;
          top: 16px;

          border-radius: 50%;
          border: 1px solid var(--green-500, #32AC82);
          background: var(--White, #FFF);

          display: flex;
          align-items: center;
          justify-content: center;

          width: 32px;
          height: 32px;
          transition: background-color .2s ease-in;

          @media screen and (min-width: 1023px) {
            &:hover {
              cursor: pointer;
              background: var(--green-200, #94FFDA);
            }
          }

          &:active {
            background: var(--green-200, #94FFDA);
          }

          svg {
            width: 24px;
            height: auto;
            display: block;
          }
        }
      }

      .title-text {
        margin-top: 8px;
        margin-bottom: 8px;

        color: #FFF;
        text-align: center;

        font-size: 22px;
        font-weight: 700;
        line-height: 140% !important;
        text-transform: uppercase;
        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }

      .btn-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;

        @media screen and (max-width: 600px) {
          grid-template-columns: 1fr;
          grid-template-rows: 1fr  1fr;

          grid-gap: 4px;
        }

        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          height: 86px;

          border: 1px solid var(--green-300, #3CEAAE);
          background: var(--grey-t-80, rgba(18, 23, 34, 0.80));

          transition: all .2s ease;
          @media screen and (max-height: 830px) and (min-width: 601px) {
            padding: 8px;
            height: 40px;
          }

          @media screen and (max-width: 600px) {
            padding: 8px;
            height: 40px;
          }

          &:not(.selected):not(.disable) {
            @media screen and (min-width: 1023px) {

              &:hover {
                cursor: pointer;
                border-color: var(--green-200, #94FFDA);
                background: var(--bg, #94FFDA);


                p {
                  color: #06243E;
                }
              }
            }

            &:active {
              border-color: var(--green-200, #94FFDA);
              background: var(--bg, #94FFDA);

              p {
                color: #06243E;
              }

            }
          }

          &.disable {
            opacity: 0.5;
          }

          &.incorrect {
            background-color: rgba(225, 0, 0, 1);
            border-color: white;

            p {
              color: white;
            }

            animation: blinkIncorrect 1.5s linear;

          }

          &.correct {
            background: var(--green-300, #3CEAAE);
            border-color: var(--White, #FFF);
            animation: blinkCorrect 1.5s linear;

            p {
              color: #06243E;
            }
          }

          p {
            color: var(--green-300, #3CEAAE);
            text-align: center;

            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal !important;
            text-transform: uppercase;
            transition: all .2s ease;
            @media screen and (max-width: 600px) {
              font-size: 14px;
            }
            @media screen and (max-height: 830px) and (min-width: 601px) {
              font-size: 14px;
            }
          }
        }
      }
    }

    &__result {
      padding: 24px 16px;
      border: 1px solid var(--gr-otline-frame, #44A483);
      background: var(--grey-t-80, rgba(18, 23, 34, 0.80));

      display: flex;
      align-items: center;
      flex-direction: column;
      height: calc(100%);

      .m-b40 {
        padding-bottom: 40px;
      }

      &.correct {
      }

      &.inCorrect {
        border-color: #E10000;
      }

      @media screen and (max-width: 600px) {
        padding: 8px;
      }

      .scroll {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 16px;

        max-height: calc((var(--vh2, 1vh) * 100) - 64px - 65px - 102px - 79px - 64px - 40px);
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        @media screen and (max-width: 1023px) {
          max-height: calc((var(--vh2, 1vh) * 100) - 160px - 111px - 23px - 64px - 40px);
        }

        @media screen and (max-width: 600px) {
          padding: 0 8px;
          max-height: calc((var(--vh2, 1vh) * 100) - 108px - 16px - 103px - 4px - 64px - 40px);

        }

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(241, 241, 241, 0.25);
          border-radius: 3px;

        }

        &::-webkit-scrollbar-thumb {
          background: var(--green-500, #32AC82);
          border-radius: 3px;
        }

        .grid-item {
        }

        .grid-item-first-column {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 24px;

          @media screen and (max-width: 1023px) {
            grid-template-columns: 1fr;
            grid-gap: 32px;
          }
        }

      }

      &--title {
        color: var(--White, #FFF);
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 140% !important;
        margin-bottom: 16px;

        @media screen and (max-width: 600px) {
          font-size: 18px;
        }
      }

      &--text {
        color: var(--White, #FFF);
        font-size: 18px;
        font-weight: 400;
        line-height: 140% !important;

        ::v-deep i {
          font-style: italic;
        }

        ::v-deep.circle {
          color: #3CEAAE;
        }

        @media screen and (max-width: 600px) {
          font-size: 13px;
          line-height: 131% !important;
        }
      }

      &--wrapper {
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      &--btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        min-height: 64px;
        max-height: 64px;
        height: 64px;
        max-width: 400px;
        width: 100%;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;

        border-radius: 100px;
        background: var(--green-300, #3CEAAE);
        border: 1px solid #3CEAAE;

        transition: all .2s ease-in;

        @media screen and (max-width: 600px) {
          max-width: 296px;
        }

        @media screen and (min-width: 1023px) {
          &:hover {
            cursor: pointer;
            border-color: #36C695;
            background: var(--green-200, #94FFDA);
          }

          &:active {
            border-color: #36C695;
            background: var(--green-200, #94FFDA);
          }
        }

        p {
          color: var(--bg, #06243E);
          text-align: center;

          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal !important;
          text-transform: uppercase;
        }
      }
    }

    &__pack-info {
      padding: 24px 8px;
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;
      background: var(--grey-t-80, rgba(18, 23, 34, 0.80));
      border: 1px solid var(--gr-otline-frame, #44A483);

      @media screen and (max-width: 600px) {
        padding: 8px;
      }

      .scroll {

        padding: 0 8px;
        display: flex;
        flex-direction: column;

        max-height: calc((var(--vh2, 1vh) * 100) - 160px - 65px - 84px - 64px - 42px);
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        @media screen and (max-width: 1023px) {
          max-height: calc((var(--vh2, 1vh) * 100) - 160px - 111px - 33px - 64px - 40px);
        }

        @media screen and (max-width: 600px) {
          max-height: calc((var(--vh2, 1vh) * 100) - 108px - 16px - 100px - 4px - 64px - 40px);
        }

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(241, 241, 241, 0.25);
          border-radius: 3px;

        }

        &::-webkit-scrollbar-thumb {
          background: var(--green-500, #32AC82);
          border-radius: 3px;
        }

        .grid-item {
        }

        .grid-item-first-column {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 32px;
        }
      }

      &--btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        height: 64px;
        max-width: 400px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        margin-bottom: 0;

        border-radius: 100px;
        background: var(--green-300, #3CEAAE);
        border: 1px solid #3CEAAE;
        transition: all .2s ease-in;

        @media screen and (min-width: 1023px) {
          &:hover {
            cursor: pointer;
            border-color: #36C695;
            background: var(--green-200, #94FFDA);
          }

          &:active {
            border-color: #36C695;
            background: var(--green-200, #94FFDA);
          }
        }

        @media screen and (max-width: 600px) {
          max-width: 296px;
        }

        p {
          color: var(--bg, #06243E);
          text-align: center;

          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal !important;
          text-transform: uppercase;
          @media screen and (max-width: 600px) {

            color: var(--bg, #06243E);
            text-align: center;

            /* desktop/d-btn */
            font-family: "IBM Plex Mono";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }
        }
      }

      &--text {
        color: var(--White, #FFF);
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 140% !important;
        margin-bottom: 24px;

        @media screen and (max-width: 600px) {
          font-size: 15px;
        }
      }

      &--wrapper {
        padding: 16px;
        background: var(--grey-t-30, rgba(66, 80, 115, 0.30));

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
        margin-bottom: 32px;

        @media screen and (max-width: 1023px) {
          grid-template-columns: 1fr;
          grid-gap: 32px;

          //background: none;
          padding: 16px;

        }

        @media screen and (max-width: 600px) {
          padding: 8px;
          grid-gap: 24px;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      &--item {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      &--item-title {
        color: var(--White, #FFF);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140% !important;
        margin-bottom: 16px;

        &.second {
          margin-top: 24px;
          @media screen and (max-width: 600px) {
            margin-top: 16px;
          }
        }

        @media screen and (max-width: 600px) {
          font-size: 13px;
        }
      }

      &--item-big-text {
        color: var(--White, #FFF);
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 140% !important;
        margin-bottom: 16px;


        @media screen and (max-width: 600px) {
          font-size: 15px;
        }
      }

      &--item-text {
        color: var(--White, #FFF);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140% !important;

        @media screen and (max-width: 600px) {
          font-size: 13px;
          line-height: 131% !important;
        }

        ::v-deep i {
          font-style: italic;

        }

        ::v-deep .new-text{
          color: var(--White, #FFF);
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 140% !important;

          @media screen and (max-width: 600px) {
            font-size: 13px;
          }
        }

        ::v-deep .list {

          line-height: 140% !important;

          .circle {
            color: #3CEAAE;
          }
        }
      }

      &--links {
        padding: 0 16px;
        position: relative;
        padding-top: 10px;
        margin-bottom: 40px;

        @media screen and (max-width: 600px) {
          margin-bottom: 24px;
          padding: 8px;
          padding-bottom: 0;
        }

        &:before {
          content: '';
          width: calc(100% - 32px);
          height: 1px;
          background: #3CEAAE;
          position: absolute;

          top: 0;
          left: 0;
          right: 0;
          margin-right: auto;
          margin-left: auto;

          @media screen and (max-width: 600px) {
            width: calc(100% - 16px);

          }
        }

        li {
          color: var(--White, #FFF);

          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 140% !important;
          letter-spacing: -0.22px;

          @media screen and (max-width: 600px) {
            font-size: 9px;
          }

          ::v-deep a {
            color: var(--White, #FFF);

            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 140% !important;
            letter-spacing: -0.22px;
            text-decoration-line: underline;

            word-wrap: break-word;

            @media screen and (max-width: 600px) {
              font-size: 9px;
            }
          }
        }
      }
    }

    .tips-popup-wrapper {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 9px;
    }

    .tips-popup {
      position: relative;
      border: 1px solid rgba(38, 158, 117, 0.20);
      background: var(--grey-t-30, rgba(66, 80, 115, 0.30));
      max-width: calc(668px + 32px);
      width: 100%;

      padding: 8px;
      margin: auto;
      @media screen and (max-width: 600px) {

        margin-left: 8px;
        margin-right: 8px;
        padding: 8px;

      }

      .corner {

        svg {

        }

        &.corner1 {

        }

        &.corner2 {

        }

        &.corner3 {

        }

        &.corner4 {

        }
      }

      &__content {
        border: 1px solid var(--green-500, #32AC82);

        background: rgba(9, 35, 57, 0.80);
      }

      &__title {
        background: var(--green-300, #3CEAAE);
        padding: 16px 0;

        p {
          color: var(--bg, #06243E);
          text-align: center;
          line-height: 40px !important;
          font-size: 50px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

      &__text {
        padding: 32px 60px;

        p {
          color: var(--White, #FFF);
          text-align: center;

          font-size: 22px;
          font-weight: 400;
          line-height: 140% !important;
        }
      }

      &__btn-wrapper {

        display: grid;
        grid-template-columns: 1fr 1fr;

        grid-gap: 10px;
        padding: 0 40px 16px 40px;


        .btn {
          @media screen and (min-width: 1023px) {
            &:hover {

              p {
                background: var(--green-300, #94FFDA) !important;
                color: #06243E;
              }
            }
          }

          width: 100%;
          display: flex;
          justify-content: center;

          &:active {

            p {
              background: var(--green-300, #94FFDA);
              color: #06243E;
            }
          }

          p {
            max-width: 400px;
            width: 100%;
            display: flex;
            justify-content: center;
            cursor: pointer;

            border-radius: 32px;
            border: 1px solid var(--green-300, #3CEAAE);
            background: var(--grey-t-80, rgba(18, 23, 34, 0.80));

            transition: color .2s ease, background .2s ease;
            padding: 21px 16px;
            color: var(--green-300, #3CEAAE);
            text-align: center;

            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;

            &.color {
              background-color: #3CEAAE;
              color: #06243E;
            }
          }
        }
      }
    }
  }

  .timeout {
    position: fixed;
    left: 8px;
    top: 0;
    bottom: 0;
    right: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
      position: relative;
      padding: 16px;
      border: 1px solid rgba(38, 158, 117, 0.20);
      background: var(--grey-t-30, rgba(66, 80, 115, 0.30));
      max-width: 668px;
      width: 100%;

      @media screen and (max-width: 600px) {
        padding: 8px;
      }
    }

    &__content {
      background: rgba(9, 35, 57, 0.60);
      display: flex;
      flex-direction: column;
    }

    &__title {
      background: var(--red, #E10000);
      padding: 16px;

      @media screen and (max-width: 600px) {
        padding: 8px;
      }

      p {
        color: var(--White, #FFF);
        text-align: center;
        font-size: 50px;
        font-weight: 400;
        line-height: 80% !important;
        text-transform: uppercase;

        @media screen and (max-width: 600px) {
          font-size: 32px;
          line-height: 80% !important;
        }
      }
    }

    &__text {
      padding: 32px 60px;
      color: var(--White, #FFF);
      text-align: center;

      font-size: 22px;
      font-weight: 400;
      line-height: 140% !important;

      @media screen and (max-width: 600px) {
        padding: 16px;
        font-size: 15px;
        line-height: 130% !important;
        letter-spacing: -0.15px;
      }
    }

    &__border {
      padding: 0 16px;
      border: 1px solid var(--green-500, #32AC82);

    }

    &__btn {
      margin-top: 40px;
      margin-bottom: 24px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      height: 64px;
      max-width: 400px;
      width: 100%;


      border-radius: 32px;
      border: 1px solid var(--green-300, #3CEAAE);
      background: var(--grey-t-80, rgba(18, 23, 34, 0.80));
      transition: background-color .2s ease;

      @media screen and (max-width: 600px) {
        margin-top: 24px;
        max-width: 280px;
        height: 56px;
      }

      @media screen and (min-width: 1023px) {
        &:hover {
          cursor: pointer;
          background: var(--green-300, #3CEAAE);

          p {
            color: #06243E;

          }
        }
        &:active {

          background: var(--green-300, #3CEAAE);

          p {
            color: #06243E;

          }
        }
      }

      p {
        color: var(--green-300, #3CEAAE);
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: normal !important;
        text-transform: uppercase;

        transition: color .2s ease;

        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
    }
  }

  .finish {
    position: relative;
    z-index: 2;
    background: var(--grey-t-30, rgba(66, 80, 115, 0.30));
    max-width: calc(668px + 32px);
    width: 100%;

    padding: 16px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 600px) {
      padding: 8px;
      margin-left: 8px;
      margin-right: 8px;
    }

    &__border {
      border: 1px solid rgba(38, 158, 117, 0.20);

    }

    &__content {
      border: 1px solid var(--green-500, #32AC82);
      background: rgba(9, 35, 57, 0.60);
    }

    &__title {
      background: var(--green-300, #3CEAAE);
      padding: 16px 0;

      @media screen and (max-width: 600px) {
        padding: 8px;
      }

      p {
        color: var(--bg, #06243E);
        text-align: center;
        line-height: 40px !important;
        font-size: 50px;
        font-weight: 400;
        text-transform: uppercase;

        @media screen and (max-width: 600px) {
          font-size: 32px;
          line-height: 80% !important;
        }
      }
    }

    &__text {
      padding: 32px 60px;

      @media screen and (max-width: 600px) {
        padding: 16px;
      }

      p {
        color: var(--White, #FFF);
        text-align: center;

        font-size: 22px;
        font-weight: 400;
        line-height: 140% !important;

        @media screen and (max-width: 600px) {
          font-size: 15px;
          line-height: 130% !important;
          letter-spacing: -0.15px;
        }
      }
    }

    &__btn {

      @media screen and (min-width: 1023px) {
        &:hover {

          p {
            background: var(--green-300, #3CEAAE);
            color: #06243E;
          }
        }
      }

      padding: 0 16px;
      margin: 40px auto 24px auto;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 600px) {
        margin-top: 24px;
      }

      &:active {

        p {
          background: var(--green-300, #3CEAAE);
          color: #06243E;
        }
      }

      p {
        max-width: 400px;
        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;

        border-radius: 32px;
        border: 1px solid var(--green-300, #3CEAAE);
        background: var(--grey-t-80, rgba(18, 23, 34, 0.80));

        transition: color .2s ease, background .2s ease;
        padding: 21px 16px;
        color: var(--green-300, #3CEAAE);
        text-align: center;

        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;

        @media screen and (max-width: 600px) {
          max-width: 280px;
          font-size: 16px;
          padding: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .tips-popup {

    margin-left: 8px;
    margin-right: 8px;
    padding: 8px;

    .corner {

      svg {

      }

      &.corner1 {

      }

      &.corner2 {

      }

      &.corner3 {

      }

      &.corner4 {

      }
    }

    &__content {

    }

    &__title {
      padding: 8px;

      p {
        font-size: 32px;

      }
    }

    &__text {

      padding: 16px;

      p {
        font-size: 15px;

      }
    }

    &__btn-wrapper {
      padding: 0 16px;
    }

    &__btn {


      @media screen and (min-width: 1023px) {
        &:hover {

          p {
          }
        }
      }

      &:active {

        p {
        }
      }

      p {
        font-size: 16px;

        padding: 17px 8px;
      }
    }
  }
}

</style>